if (document.readyState !== 'loading') {
    handleLoad();
}
else {
    document.addEventListener('DOMContentLoaded', handleLoad);
}
function handleLoad() {
    const widgetRoots = toArray(document.querySelectorAll('a[href="https://simplesat.io"]'));
    widgetRoots.forEach((widgetRoot) => {
        const replacementNode = document.createElement('div');
        copyAttributes(widgetRoot, replacementNode);
        const iframe = document.createElement('iframe');
        iframe.style.width = '100%';
        iframe.style.border = 'none';
        iframe.title = 'Feedback widget';
        // Apparently Firefox will change an iframe element
        // once it's loaded, so we need defer any mutation
        // to iframe DOM until it's loaded.
        // https://bugzilla.mozilla.org/show_bug.cgi?id=728151#c4
        iframe.onload = () => {
            const iframeDocument = iframe.contentWindow?.document;
            const appRoot = createElement(iframeDocument, 'div', {
                id: 'app',
            });
            iframeDocument.body.appendChild(appRoot);
            // get the location of the script
            const SCRIPT_ID = 'simplesat-widget-script';
            const scriptHostName = document.getElementById(SCRIPT_ID).src
                .split('/')
                .slice(2, -1)
                .join('/');
            //Config: we using Absolute style.css url becuase widget script after build cannot load relative style
            const moduleScript = createElement(iframeDocument, 'script', {
                src: `https://${scriptHostName}/widget-module.js`,
                type: 'module',
            });
            const legacyScript = createElement(iframeDocument, 'script', {
                src: `https://${scriptHostName}/widget-legacy.js`,
                type: 'systemjs-module',
                noModule: true,
            });
            const systemjsScript = createElement(iframeDocument, 'script', {
                src: `https://${scriptHostName}/systemjs.js`,
                noModule: true,
            });
            function onScriptLoad() {
                iframe.dispatchEvent(createEvent('svelte-mount'));
            }
            moduleScript.onload = onScriptLoad;
            legacyScript.onload = onScriptLoad;
            const iframeHead = iframe.contentWindow?.document.head;
            iframeHead.appendChild(moduleScript);
            iframeHead?.appendChild(legacyScript);
            iframeHead.appendChild(systemjsScript);
        };
        replacementNode.appendChild(iframe);
        widgetRoot.parentNode?.replaceChild(replacementNode, widgetRoot);
    });
}
function copyAttributes(fromNode, toNode) {
    toArray(fromNode.attributes).forEach((attribute) => {
        if (attribute.name !== 'href') {
            toNode.setAttribute(attribute.name, attribute.value);
        }
    });
}
function createElement(document, elementName, attributes) {
    const element = document.createElement(elementName);
    entries(attributes).forEach(([attribute, value]) => {
        element[attribute] = value;
    });
    return element;
}
function toArray(elements) {
    return [].slice.call(elements, 0);
}
function createEvent(eventName) {
    if (typeof Event === 'function') {
        return new Event(eventName);
    }
    const event = document.createEvent('Event');
    event.initEvent(eventName, true, true);
    return event;
}
function entries(object) {
    return Object.keys(object).map((key) => {
        return [key, object[key]];
    });
}
export {};
